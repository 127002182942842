import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box, FormGroup, Stack, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Topbar from "../../global/topbar/Topbar";
import Sidebar from "../../global/sidebar/Sidebar";
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, TextareaAutosize, Button, Avatar, Grid } from '@mui/material';
import WelcomeMessage from './WelcomeMessage';
import CompanyLogo from "../../../utilityComponents/images/iconPNG.png";
import axios from 'axios';
import { ColorModeContext, tokens, themeSettings } from "../../../utilityComponents/theme";
import { useResponsiveQueries } from '../../../utilityComponents/device';
import { useSelector } from 'react-redux';
import Switch from '@mui/material/Switch';

const AiIcon = "https://cdn-icons-png.flaticon.com/512/9887/9887771.png";
const LoadingGif = "https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${theme.drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'Arial, sans-serif',
  padding: '20px',
  width: '70%',
});

const StyledGrid = styled(Grid)({
  fontFamily: 'Open Sans',
  fontSize: '64px',
  fontWeight: '400',
  lineHeight: '72px',
  letterSpacing: '-0.015em',
  textAlign: 'center',
  marginBottom: '20px',
  background: 'linear-gradient(180deg, #17DFE9 0%, #3A9DF2 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  position: 'relative',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'inherit',
  zIndex: 1,
});

const StyledLogo = styled('img')({
  width: '100px',
  height: 'auto',
  marginBottom: '10px',
});

const MessageContainer = styled('div')(({ isUser }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: isUser ? 'flex-start' : 'flex-end',
  marginBottom: '10px',
  width: "50%",
}));

const StyledMessageText = styled(Typography)(({ isUser }) => ({
  backgroundColor: isUser ? '#f0f0f0' : '#e1f5fe',
  padding: '10px',
  borderRadius: '10px',
  fontSize: '16px',
  color: 'black',
}));

const MessageText = ({ isUser, children }) => {
  return (
    <StyledMessageText variant="body1" isUser={isUser}>
      {children}
    </StyledMessageText>
  );
};

const InputContainerGrid = styled(Grid)({
  width: '100%',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
});

const UserInput = styled('textarea')({
  backgroundColor: '#9F9F9F',
  color: 'black',
  flex: '1',
  padding: '8px',
  fontSize: '16px',
  minHeight: '80px',
  resize: 'vertical',
  borderRadius: '5px',
});

const LoadingIcon = styled('img')({
  width: '20px',
  marginRight: '5px',
});

const Medicalassistant = () => {
  const theme = useTheme();
  const colors = tokens("dark");
  const themeStyles = themeSettings("dark");

  const [userInput, setUserInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckedQ, setIsCheckedQ] = useState(false);
  const inputRef = useRef(null);
  const messageContainerRef = useRef(null);
  const subWelcomeMessage = "You can ask questions about your medical reports (source documents). NOVU Medical assistance is currently under preview. NOVU GenAI is Multilingual.";

  const apiName = 'https://2qqtk3f5qvwr5x4355xkewm4uu0eyjiu.lambda-url.eu-central-1.on.aws/';
  const pathq = '/qbusiness';
  const { isMobile } = useResponsiveQueries();
  const user = useSelector((state) => state.user);
  const HumanIcon = user.avatarUrl;
  const processedMessageIDs = new Set();
  const [connId, setConnId] = useState(null);
  const [socketConn, setSocketConn] = useState(null);

  const handleSwitchQChange = (event) => {
    setIsCheckedQ(event.target.checked);
    console.log("Event", event.target.checked);
  };

  const sendFirstMessage = async () => {
    try {
      console.log("Sending initial request...");
      await axios.post(
        apiName,
        {
          userMessage: "I have some questions about my reports stored in the knowledge base. Can you help me answering them",
          userId: "20",
        }
      );
    } catch (error) {
      console.error('POST call failed: ', error);
    }
  };

  useMemo(() => { sendFirstMessage(); }, []);

  useEffect(() => {
    const conn = new WebSocket(`wss://tb0n3s8qm3.execute-api.eu-central-1.amazonaws.com/dev?userId=${user.userID}`);

    conn.addEventListener('open', () => {
      console.log('WebSocket is connected', conn);
      setSocketConn(conn);
    });

    conn.addEventListener('close', () => {
      console.log('WebSocket Connection is closed');
    });

    conn.addEventListener('error', (error) => {
      console.error('WebSocket Connection is in error', error);
    });

    conn.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.connId) {
        setConnId(data.connId);
      } else if (data.message) {
        console.log('Message from server:', data.message);

        const messageID = data.message.messageID;

        if (!processedMessageIDs.has(messageID)) {
          receivedMessage(data.message.data);
          processedMessageIDs.add(messageID);
        } else {
          console.log('Duplicate message ignored:', messageID);
        }
      }
    });

    return () => {
      conn.close();
    };
  }, [user.userID]);

  function sendMessage(userInput) {
    if (socketConn) {
      const payload = {
        eventType: "CLIENTMESSAGE",
        user_id: user.userID,
        conversation_id: "20",
        message: userInput,
      };
      socketConn.send(JSON.stringify(payload));
      console.log("Sending request...");
      setUserInput("");
    } else {
      console.error("WebSocket connection not established yet.");
    }
  }

  function receivedMessage(response) {
    setIsLoading(false);
    setChatMessages(prevMessages => prevMessages.filter(msg => msg.loading !== true));

    const newBotMessage = { message: response, isUser: false };
    setChatMessages(prevMessages => [...prevMessages, newBotMessage]);
  }

  useEffect(() => {
    console.log(isMobile, "isMobile");

    setTimeout(() => {
      const botResponse = "How can I help you today?";
      const botMessage = { message: botResponse, isUser: false };
      setChatMessages(prevMessages => [...prevMessages, botMessage]);
      scrollToBottom();
    }, 1000);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (!userInput) return;

    sendMessageBedrock(userInput);
  };

  function sendMessageBedrock(userInput) {
    const newUserMessage = { message: userInput, isUser: true };
    setChatMessages(prevMessages => [...prevMessages, newUserMessage]);

    setIsLoading(true);

    const loadingMessage = { message: "Thinking...", isUser: false, loading: true };
    setChatMessages(prevMessages => [...prevMessages, loadingMessage]);

    try {
      sendMessage(userInput);
    } catch (error) {
      console.error('Message sending failed: ', error);
      setIsLoading(false);
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Topbar />
      <Sidebar />
      <Main>
        <Toolbar variant="dense" />
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {/* START PAGE CONTENT */}
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {chatMessages.slice(1).length > 1 ? "" :
              <StyledGrid item xs={12} sm={12}>
                <StyledLogo src={CompanyLogo} alt="Company Logo" />
                <WelcomeMessage />
                <Typography
                  sx={[themeStyles.typography.h60, { color: colors.dark_grey[100] }]}
                >{subWelcomeMessage}</Typography>
              </StyledGrid>
            }
            {/* Chat Messages Container */}
            <div
              ref={messageContainerRef}
              style={{ flexGrow: 1, overflowY: 'auto' }}
            >
              <Grid container spacing={2}>
                {chatMessages.slice(1).map((message, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      justifyContent: message.isUser ? "flex-start" : "flex-end",
                    }}
                  >
                    <MessageContainer isUser={message.isUser}>
                      <Avatar src={message.isUser ? HumanIcon : AiIcon} alt="Icon"
                        sx={{ width: "30px", height: "30px", marginRight: '10px' }}
                      />
                      <MessageText isUser={message.isUser}>
                        {message.loading ? (
                          <LoadingIcon src={LoadingGif} alt="Loading..." />
                        ) : (
                          message.message
                        )}
                      </MessageText>
                    </MessageContainer>
                  </Grid>
                ))}
              </Grid>
            </div>

            {/* Input Container */}
            <InputContainerGrid item xs={12} sm={12} id={"Textarea"}>
              <UserInput
                ref={inputRef}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="How can I help you today?"
              />
              <LoadingButton onClick={handleSendMessage}
                loading={isLoading}
                endIcon={<SendIcon />}
                variant="contained" sx={{
                  marginLeft: '20px',
                  padding: '8px 16px',
                  height: "90px",
                  fontSize: '16px',
                  cursor: 'pointer',
                  backgroundColor: '#17DFE9',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <span>Send</span>
              </LoadingButton>
            </InputContainerGrid>
          </Box>
          {/* END PAGE CONTENT */}
        </Box>
      </Main>
    </Box>
  );
};

export default Medicalassistant;
